import { initiateZendeskWidget } from "util/zendesk"

import { useEffect, useState } from "react"

import { Spacer, Flex, Text } from "@chakra-ui/react"
import { useWallets } from "@web3-onboard/react"
import { AlertModal } from "components/AlertModal"
import { Background } from "components/Background"
import { Header } from "components/header/Header"
import { TeleportTransactionView } from "components/TeleportTransactionView"
import { TeleportView } from "components/TeleportView"
import TransferModal from "components/TransferModal"
import { WarningModal } from "components/WarningModal"
import { initWeb3Onboard } from "services"
import { useActions, useAppState } from "store"
import { withUAL } from "ual-reactjs-renderer"

import { APP_CURRENT_VERSION } from "../constants"

const Teleport = ({ ual }) => {
  const actions = useActions()
  const { teleport } = useAppState()

  const connectedWallets = useWallets()

  const [, setWeb3Onboard] = useState(null)

  const [transferData, setTransferData] = useState({
    source: null,
    target: null,
  })

  const handleNext = () => {
    actions.teleport.fetchTeleportTransactions()
    actions.teleport.setTeleportStep(teleport.teleportStep + 1)
  }
  const handleBack = () => {
    actions.teleport.setTeleportStep(teleport.teleportStep - 1)
  }

  const handleTransferModalOnClose = () => {
    setTransferData({ source: null, target: null })
    actions.teleport.setTransferModal(false)
  }

  const handleTransferModalOnOpen = (data: typeof transferData) => {
    setTransferData(data)
    actions.teleport.setTransferModal(true)
  }

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
    initiateZendeskWidget()
  }, [])

  useEffect(() => {
    if (ual) actions.teleport.setUAL(ual)
  }, [ual])
  useEffect(() => {
    if (connectedWallets.length > 0) {
      actions.teleport.setWeb3Wallet(connectedWallets[0])
    }
  }, [connectedWallets])

  return (
    <Flex width="full" direction="column" minHeight="100vh">
      <Background />
      <Header />

      <TeleportView
        ual={ual}
        handleNext={handleNext}
        handleTransferModalOnOpen={handleTransferModalOnOpen}
      />
      <TeleportTransactionView ual={ual} handleBack={handleBack} />

      <WarningModal />
      {teleport.transferModal && (
        <TransferModal transferData={transferData} onClose={handleTransferModalOnClose} />
      )}

      <AlertModal />

      <Spacer />
      <Flex paddingInline={5} paddingBlock={5}>
        <Text fontFamily="Titillium Web"> UI VERSION: {APP_CURRENT_VERSION}</Text>
      </Flex>
    </Flex>
  )
}

export default withUAL(Teleport)
