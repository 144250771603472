import { IContext } from "overmind"
import { namespaced } from "overmind/config"
import { createActionsHook, createStateHook, createEffectsHook } from "overmind-react"

import * as teleport from "./teleport"

export const config = namespaced({
  teleport: teleport.config,
})

export type Context = IContext<typeof config>

export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
export const useEffects = createEffectsHook<Context>()
