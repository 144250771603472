export const sleep = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export enum Chains {
  BINANCE = "0x38",
  ETH = "0x1",
}

export function msToTime(duration) {
  // const milliseconds = Math.floor((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  return {
    hours,
    minutes,
    seconds,
    isZero: seconds <= 0 && minutes <= 0 && hours <= 0,
  }
}

export function padTimeWithZero(time) {
  return time < 10 ? `0${time}` : `${time}`
}

export function padStringWithZeros(str: string) {
  const paddedString = str.padEnd(20, "0")
  const substring = paddedString.substring(2)
  const integer = parseInt(substring, 10) / 10000
  return integer.toFixed(4)
}
export function BSCorETH(str: string) {
  if (str === Chains.BINANCE) {
    return { label: "BNB Liquid Balance", switchLabel: "Switch to ETH" }
  }
  if (str === Chains.ETH) {
    return { label: "ETH Liquid Balance", switchLabel: "Switch to BNB" }
  }
  return { label: "", switchLabel: "" }
}
export function isBSC(str: string) {
  if (str === Chains.BINANCE) {
    return true
  }
  return false
}
export function openInNewTab(url: string) {
  const win = window.open(url, "_blank")
  win.focus()
}
