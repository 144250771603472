import { useEffect } from "react"

import { TeleportIcon } from "@alien-worlds/icons"
import { Button } from "@alien-worlds/uikit"
import { Flex } from "@chakra-ui/react"
import { useWallets } from "@web3-onboard/react"
import { TeleportTransactionViewItem } from "components/TeleportTransactionViewItem"
import { map } from "lodash"
import LazyLoad from "react-lazyload"
import { useInterval } from "react-use"
import { useActions, useAppState } from "store"
import { Transaction } from "store/teleport/types"
import { v4 as uuidv4 } from "uuid"

const TeleportTransactionView = ({ ual, handleBack }: { ual: any; handleBack: () => void }) => {
  const connectedWallets = useWallets()
  const actions = useActions()
  const { teleport } = useAppState()
  useEffect(() => {
    if (
      teleport.teleportStep === 2 &&
      ual &&
      ual.activeAuthenticator &&
      connectedWallets.length > 0
    ) {
      actions.teleport.fetchTeleportTransactions()
    }
  }, [ual, connectedWallets])

  useInterval(() => {
    if (teleport.teleportStep === 2) {
      actions.teleport.fetchTeleportTransactions()
    }
  }, 5000)

  if (teleport.teleportStep !== 2) return null
  return (
    <Flex direction="column" alignItems="center" gap={2}>
      <TeleportIcon boxSize={96} />
      <Button variant="warning" size="lg" onClick={() => handleBack()}>
        New Transfer
      </Button>
      <Flex direction="column">
        {map(teleport.transactions, (trans: Transaction) => {
          return (
            <LazyLoad key={uuidv4()} height="10vh">
              <Flex
                justifyContent="center"
                borderTopStyle="solid"
                borderTopColor="#d3d3d3"
                borderTopWidth={["1px", "1px", "1px", "1px", "0", "0"]}
              >
                <TeleportTransactionViewItem item={trans} ual={ual} />
              </Flex>
            </LazyLoad>
          )
        })}
      </Flex>
    </Flex>
  )
}
export { TeleportTransactionView }
