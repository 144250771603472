import { AlienWorldsIcon } from "@alien-worlds/icons"
import { Flex, Divider, Text } from "@chakra-ui/react"
import { Colors } from "colors"

const Logo = () => {
  return (
    <Flex color={Colors.SNOW_WHITE} gap={4} alignItems="center">
      <AlienWorldsIcon width={75} height={27} />
      <Divider orientation="vertical" borderWidth={1} color={Colors.SNOW_WHITE} height={35} />
      <Text fontSize="16px" fontWeight="700" letterSpacing="2px" textTransform="uppercase">
        teleport
      </Text>
    </Flex>
  )
}

export { Logo }
