import pkg from "../package.json"

export const AccountType = {
  WAX: "WAX",
  ANCHOR: "Anchor",
  BINANCE: "Binance",
  ETH: "Ethereum",
}
export const APP_CURRENT_VERSION = pkg.version
export const WaxAccounts = [AccountType.WAX, AccountType.ANCHOR]
export const MetamaskAccounts = [AccountType.BINANCE, AccountType.ETH]

export const SUPPORT_URL =
  "https://explorersstation.alienworlds.io/hc/en-us/articles/1500012387282-How-do-I-Teleport-Trilium-TLM-tokens-between-WAX-and-ETH-or-BSC-"
