import { isBSC } from "util/helpers"
import { walletAddressTruncate } from "util/string"

import { BenanceIcon, MetamaskIcon2, TriliumIcon2, WaxIcon2 } from "@alien-worlds/icons"
import { Divider, Flex, Text, Box, Show } from "@chakra-ui/react"
import { useSetChain, useWallets } from "@web3-onboard/react"
import { Colors } from "colors"
import { get } from "lodash"
import { useAppState } from "store"

const WaxAccount = () => {
  const { teleport } = useAppState()

  if (!get(teleport, "account.wax.name")) return <></>

  return (
    <Flex gap={4}>
      <Show above="md">
        <WaxIcon2 width={73} height={27} />
      </Show>

      <Text fontSize="md" fontWeight="bold">
        {get(teleport, "account.wax.name")}
      </Text>
    </Flex>
  )
}

const MetaMaskAccount = () => {
  const connectedWallets = useWallets()
  const [{ connectedChain }] = useSetChain()

  if (!get(connectedWallets, "[0].accounts[0].address")) return <></>

  return (
    <>
      <Flex gap={4} alignItems="center">
        <Show above="md">
          <MetamaskIcon2 width={29.5} height={27} color={Colors.TUSCANY} />
        </Show>

        <Text fontSize="md" fontWeight="bold">
          {walletAddressTruncate(get(connectedWallets, "[0].accounts[0].address"))}
        </Text>

        <Box display={{ base: "none", md: "block" }}>
          {isBSC(connectedChain?.id) && <BenanceIcon boxSize={30} color={Colors.SNOW_WHITE} />}
          {connectedWallets.length > 0 && !isBSC(connectedChain?.id) && (
            <TriliumIcon2 boxSize={30} color={Colors.SNOW_WHITE} />
          )}
        </Box>
      </Flex>
    </>
  )
}

const WalletAccounts = () => {
  const { teleport } = useAppState()
  const connectedWallets = useWallets()

  return (
    <Flex gap={4} alignItems="center">
      <WaxAccount />

      {get(teleport, "account.wax.name") && get(connectedWallets, "[0].accounts[0].address") && (
        <Divider borderWidth={1} orientation="vertical" height={35} />
      )}

      <MetaMaskAccount />
    </Flex>
  )
}

export { WalletAccounts }
