import { CrossIcon } from "@alien-worlds/icons"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react"
import { Colors } from "colors"
import { useAppState } from "store"

const AlertModal = () => {
  const {
    teleport: { alertModal },
  } = useAppState()

  const AlertTitle = () => {
    if (alertModal?.type === "success") {
      return (
        <Flex direction="column" gap={7} alignItems="center">
          <Box boxSize={63} border={`5px solid ${Colors.CARIBBEAN_GREEN}`} borderRadius="full" />
          <Text
            fontFamily="Orbitron"
            fontSize="23px"
            color={Colors.CARIBBEAN_GREEN}
            letterSpacing="2px"
          >
            Success
          </Text>
        </Flex>
      )
    }

    if (alertModal?.type === "error") {
      return (
        <Flex direction="column" gap={2} alignItems="center">
          <CrossIcon boxSize={125} color={Colors.RADICAL_RED} />
          <Text
            fontFamily="Orbitron"
            fontSize="23px"
            color={Colors.RADICAL_RED}
            letterSpacing="2px"
          >
            Error
          </Text>
        </Flex>
      )
    }
  }

  return (
    <Modal size="full" isOpen={alertModal.doShow} onClose={alertModal?.onClose}>
      <ModalOverlay backgroundColor={Colors.BLACK_SOLID_70} backdropFilter="blur(5px)" />
      <ModalContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={50}
        background="transparent"
      >
        <ModalBody display="flex" alignItems="center" justifyContent="center" w="100%">
          <Flex direction="column" gap={16} alignItems="center">
            <AlertTitle />
            <Text fontFamily="Titillium Web" fontSize="23px" textAlign="center">
              {alertModal?.message}
            </Text>
            <Button
              height={12}
              minWidth={22}
              width={92}
              fontFamily="Orbitron"
              fontSize="14px"
              letterSpacing="1px"
              color={Colors.SNOW_WHITE}
              fontWeight="500"
              textTransform="uppercase"
              borderRadius="0"
              backgroundColor="transparent"
              border={`2px solid ${Colors.SNOW_WHITE}`}
              _hover={{
                backgroundColor: Colors.SNOW_WHITE,
                color: Colors.BLACK_SOLID_100,
                transform: "scale(0.95)",
                transition: "0.3s",
              }}
              onClick={() => alertModal?.onClose()}
            >
              OK
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { AlertModal }
