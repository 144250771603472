const initiateZendeskWidget = () => {
  const script = document.createElement("script")

  // check if script is already loaded
  const existingScript = document.getElementById("ze-snippet")
  if (existingScript) return

  script.id = "ze-snippet"
  script.src = process.env.REACT_APP_ZENDESK_URL
  script.async = true
  document.body.appendChild(script)
}

export { initiateZendeskWidget }
