import { BSCorETH, isBSC, openInNewTab, padStringWithZeros } from "util/helpers"
import { config } from "util/teleportConfig"

import { useEffect } from "react"

import {
  AlienWorldsIcon,
  BenanceIcon,
  TriliumIcon2,
  MetamaskIcon2,
  TrustWalletIcon,
  WalletConnectIcon,
  WaxIcon2,
  AnchorIcon,
  SwitchIcon,
} from "@alien-worlds/icons"
import { Button } from "@alien-worlds/uikit"
import {
  Flex,
  Text,
  Button as UIButton,
  GridItem,
  Grid,
  useBreakpointValue,
  Show,
  Hide,
  Box,
} from "@chakra-ui/react"
import { useConnectWallet, useSetChain, useWallets } from "@web3-onboard/react"
import { Colors } from "colors"
import { motion } from "framer-motion"
import { get, includes } from "lodash"
import { useActions, useAppState } from "store"

import { AccountType, SUPPORT_URL } from "../constants"

const MotionDiv = ({ children }) => {
  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0, transition: { duration: 0.15 } }}
      transition={{ duration: 0.3, delay: 0.3 }}
    >
      {children}
    </motion.div>
  )
}

const TeleportView = ({
  ual,
  handleNext,
  handleTransferModalOnOpen,
}: {
  ual: any
  handleNext: () => void
  handleTransferModalOnOpen: ({ source, target }) => void
}) => {
  const actions = useActions()

  const { teleport } = useAppState()
  const connectedWallets = useWallets()
  const column = useBreakpointValue({ base: 1, lg: 3 })
  const buttonSize = useBreakpointValue({ base: "sm", md: "lg" })

  const [, connect] = useConnectWallet()

  const [{ connectedChain }, setChain] = useSetChain()

  useEffect(() => {
    if (ual) actions.teleport.setUAL(ual)
  }, [ual])
  useEffect(() => {
    if (connectedWallets.length > 0) {
      actions.teleport.setWeb3Wallet(connectedWallets[0])
    }
  }, [connectedWallets])
  if (teleport.teleportStep !== 1) return null
  return (
    <MotionDiv>
      <Flex direction="column" alignItems="center" gap={2} px={2} my={{ base: 5, md: 10 }}>
        <AlienWorldsIcon boxSize={140} height="auto" />
        <Text
          fontFamily="Titillium Web"
          color={Colors.DI_SERRIA}
          fontSize="40px"
          letterSpacing="0.5rem"
        >
          TELEPORT
        </Text>
        <Text
          fontFamily="Titillium Web"
          color={Colors.SNOW_WHITE}
          fontSize="22px"
          fontWeight={500}
          textAlign="center"
        >
          Transfer your TLM between WAX & Metamask (on Ethereum or BNB chain)
        </Text>
        <Text
          fontFamily="Titillium Web"
          color={
            get(teleport, "account.wax.balance", false)
              ? Colors.CARIBBEAN_GREEN
              : Colors.RADICAL_RED
          }
          fontSize="15px"
          fontWeight="bold"
          textAlign="center"
        >
          {get(teleport, "account.wax.balance", false)
            ? "Next, select the chain from which to teleport"
            : "To begin with any teleports, both accounts must be logged in"}
        </Text>

        <Grid gridTemplateColumns={`repeat(${column},1fr)`} width={{ base: "full", md: "70%" }}>
          <GridItem>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap={4}
              height={{ base: 100, md: 200 }}
            >
              {/* Display icon for Wax or Anchor logged-in user or show both for not logged in user */}
              {includes([AccountType.WAX, null], teleport.account.wax.activeAuthenticatorName) && (
                <Box width={{ base: 100, md: 200 }} height={{ base: 100, md: 200 }}>
                  <WaxIcon2 width="inherit" height="inherit" />
                </Box>
              )}

              {includes(
                [AccountType.ANCHOR, null],
                teleport.account.wax.activeAuthenticatorName
              ) && (
                <Box width={{ base: 50, md: 100 }} height={{ base: 50, md: 100 }}>
                  <AnchorIcon width="inherit" height="inherit" />
                </Box>
              )}
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap={4}
            >
              {get(teleport, "account.wax.activeAuthenticatorName", null) && (
                <>
                  <Text fontWeight="bold" color={Colors.DI_SERRIA} fontSize={18}>
                    {get(teleport, "account.wax.activeAuthenticatorName", false)} Liquid Balance
                  </Text>
                  <Text fontFamily="Orbitron" fontWeight={500} fontSize="30px" textAlign="center">
                    {get(teleport, "account.wax.balance", "0.0")} TLM
                  </Text>
                </>
              )}

              {get(teleport, "account.wax.activeAuthenticatorName") ? (
                <UIButton
                  fontFamily="Orbitron"
                  bg="transparent"
                  borderColor={Colors.SNOW_WHITE}
                  _hover={{
                    bg: Colors.CARIBBEAN_GREEN,
                    borderColor: Colors.CARIBBEAN_GREEN,
                    color: Colors.BLACK_SOLID_100,
                  }}
                  borderWidth={1}
                  borderRadius={0}
                  size={buttonSize}
                  width="100%"
                  fontWeight="light"
                  letterSpacing={5}
                  maxW={213}
                  disabled={connectedWallets.length === 0}
                  onClick={() => {
                    handleTransferModalOnOpen({
                      source: get(teleport, "account.wax.activeAuthenticatorName", null),
                      target: isBSC(connectedChain?.id) ? AccountType.BINANCE : AccountType.ETH,
                    })
                  }}
                >
                  TRANSFER
                </UIButton>
              ) : (
                <Button
                  size={["sm", "sm", "sm", "lg", "lg", "lg"]}
                  maxW={400}
                  variant="primary"
                  onClick={() => ual.showModal()}
                >
                  Connect Wallet
                </Button>
              )}

              {get(teleport, "account.wax.activeAuthenticatorName", null) && connectedChain?.id ? (
                <Text fontWeight="bold" fontSize="15px">
                  From Wax to {isBSC(connectedChain?.id) ? "BNB" : "Ethereum"}
                </Text>
              ) : (
                <Text fontWeight="bold" fontSize="15px">
                  {"Wax "}
                  <Text as="span" textDecoration="underline">
                    or
                  </Text>
                  {" Anchor"}
                </Text>
              )}
            </Flex>
          </GridItem>
          <GridItem mr={4} ml={4}>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              minH={{ base: 230, lg: 400 }}
              transform={{ base: "rotate(90deg)", lg: "rotate(0deg)" }}
            >
              <SwitchIcon
                width={112}
                height={112}
                style={{ stroke: Colors.DI_SERRIA, strokeWidth: 0.5 }}
                color="transparent"
              />
            </Flex>
            <Show above="lg">
              {connectedWallets.length > 0 && ual?.activeUser && (
                <Flex justifyContent="center" alignItems="center" onClick={() => handleNext()}>
                  <UIButton
                    fontFamily="Orbitron"
                    bg="transparent"
                    borderColor={Colors.SNOW_WHITE}
                    _hover={{
                      bg: Colors.SNOW_WHITE,
                      borderColor: Colors.SNOW_WHITE,
                      color: Colors.BLACK_SOLID_100,
                    }}
                    borderWidth={1}
                    borderRadius={0}
                    size={buttonSize}
                    width="100%"
                    fontWeight="light"
                    maxW={213}
                    disabled={connectedWallets.length === 0}
                  >
                    Transactions
                  </UIButton>
                </Flex>
              )}
            </Show>
          </GridItem>

          <GridItem mb={4}>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              minH={{ base: 70, lg: 200 }}
              gap={4}
            >
              {connectedWallets.length === 0 && (
                <Box width={{ base: 70, md: 100 }} height={{ base: 70, md: 100 }}>
                  <TrustWalletIcon color={Colors.SNOW_WHITE} width="inherit" height="inherit" />
                </Box>
              )}
              {connectedWallets.length === 0 && (
                <Box width={{ base: 70, md: 100 }} height={{ base: 70, md: 100 }}>
                  <MetamaskIcon2 color={Colors.TUSCANY} width="inherit" height="inherit" />
                </Box>
              )}
              {connectedWallets.length === 0 && (
                <Box width={{ base: 70, md: 100 }} height={{ base: 70, md: 100 }}>
                  <WalletConnectIcon color={Colors.SNOW_WHITE} width="inherit" height="inherit" />
                </Box>
              )}

              {isBSC(connectedChain?.id) && (
                <Box width={{ base: 70, md: 100 }} height={{ base: 70, md: 100 }}>
                  <BenanceIcon color={Colors.SNOW_WHITE} width="inherit" height="inherit" />
                </Box>
              )}
              {connectedWallets.length > 0 && !isBSC(connectedChain?.id) && (
                <Box width={{ base: 70, md: 100 }} height={{ base: 70, md: 100 }}>
                  <TriliumIcon2 width="inherit" height="inherit" color={Colors.SNOW_WHITE} />
                </Box>
              )}
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap={2}
              mt={{ base: 10, md: 0 }}
            >
              {connectedWallets.length > 0 && (
                <>
                  <Text fontWeight="bold" color={Colors.DI_SERRIA} fontSize={18}>
                    {BSCorETH(connectedChain.id).label}
                  </Text>
                  <Text fontFamily="Orbitron" fontWeight={500} fontSize="30px" textAlign="center">
                    {padStringWithZeros(
                      get(connectedWallets, "[0].accounts[0].secondaryTokens[0].balance", "0.0")
                    )}{" "}
                    TLM
                  </Text>
                </>
              )}
              {connectedWallets.length > 0 && (
                <UIButton
                  fontFamily="Orbitron"
                  bg="transparent"
                  borderColor={Colors.SNOW_WHITE}
                  _hover={{
                    bg: Colors.CARIBBEAN_GREEN,
                    borderColor: Colors.CARIBBEAN_GREEN,
                    color: Colors.BLACK_SOLID_100,
                  }}
                  borderWidth={1}
                  borderRadius={0}
                  size={buttonSize}
                  width="100%"
                  fontWeight="light"
                  letterSpacing={5}
                  maxW={213}
                  mt={2}
                  disabled={!get(teleport, "account.wax.activeAuthenticatorName", null)}
                  onClick={() => {
                    handleTransferModalOnOpen({
                      source: isBSC(connectedChain?.id) ? AccountType.BINANCE : AccountType.ETH,
                      target: get(teleport, "account.wax.activeAuthenticatorName", null),
                    })
                  }}
                >
                  TRANSFER
                </UIButton>
              )}

              {connectedWallets.length === 0 && (
                <Button
                  size={["sm", "sm", "sm", "lg", "lg", "lg"]}
                  maxW={500}
                  variant="negative"
                  onClick={() => connect()}
                >
                  Connect to Wallet
                </Button>
              )}
              {connectedWallets.length === 0 && (
                <Flex justifyContent="center" alignContent="center" alignItems="center">
                  <Text fontWeight="bold" fontSize="15px">
                    Ethereum <span style={{ textDecoration: "underline" }}>or</span> Binance
                  </Text>

                  <TriliumIcon2 boxSize="44px" />
                  <BenanceIcon boxSize="44px" />
                </Flex>
              )}
              {connectedWallets.length > 0 && (
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Text fontWeight="bold" color={Colors.SNOW_WHITE} fontSize={15}>
                    {BSCorETH(connectedChain.id).switchLabel}
                  </Text>
                  <SwitchIcon
                    width={40}
                    height={32}
                    aria-label=""
                    onClick={() =>
                      setChain({
                        chainId: isBSC(connectedChain.id)
                          ? `0x${config.ethChainId}`
                          : `0x${config.bscChainId}`,
                      })
                    }
                  />
                </Flex>
              )}
              {connectedWallets.length === 0 && (
                <Box>
                  <Text fontWeight="bold" fontSize="15px" mb={-40} color={Colors.DUSTY_GRAY}>
                    Need Help?{" "}
                    <Text
                      as="span"
                      onClick={() => openInNewTab(SUPPORT_URL)}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click Here
                    </Text>
                  </Text>
                </Box>
              )}
            </Flex>
          </GridItem>
        </Grid>

        <Hide above="lg">
          {connectedWallets.length > 0 && ual?.activeUser && (
            <Flex justifyContent="center" alignItems="center" onClick={() => handleNext()}>
              <UIButton
                fontFamily="Orbitron"
                bg="transparent"
                borderColor={Colors.SNOW_WHITE}
                _hover={{
                  bg: Colors.SNOW_WHITE,
                  borderColor: Colors.SNOW_WHITE,
                  color: Colors.BLACK_SOLID_100,
                }}
                borderWidth={1}
                borderRadius={0}
                size={buttonSize}
                width="100%"
                fontWeight="light"
                maxW={213}
                disabled={connectedWallets.length === 0}
              >
                Transactions
              </UIButton>
            </Flex>
          )}
        </Hide>
      </Flex>
    </MotionDiv>
  )
}

export { TeleportView }
