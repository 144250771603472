import { isBSC } from "util/helpers"

import { useEffect, useState } from "react"

import { Box } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useSetChain } from "@web3-onboard/react"
import { motion } from "framer-motion"

import { AccountType } from "../constants"
import { useAppState } from "../store"

const MotionBox = motion(Box)

const animateGradientsLoggedInWallet = () => {
  const grad1 = document.getElementsByClassName("gradwax1")
  if (grad1) {
    grad1[0].className += " waxlog"
  }
  const grad2 = document.getElementsByClassName("gradwax2")
  if (grad2) {
    grad2[0].className += " waxlog"
  }
}

const animateOtherWallet = () => {
  const grad1 = document.getElementsByClassName("gradother1")
  if (grad1 && grad1[0]) {
    grad1[0].className += " otherlog"
  }
  const grad2 = document.getElementsByClassName("gradother2")
  if (grad2 && grad2[0]) {
    grad2[0].className += " otherlog"
  }
}

const animateGradientsLoggedOutWallet = () => {
  const grad1 = document.querySelector(".gradwax1")
  if (grad1) {
    grad1.classList.remove("waxlog")
  }
  const grad2 = document.querySelector(".gradwax2")
  if (grad2) {
    grad2.classList.remove("waxlog")
  }
}

const Background = () => {
  const { teleport } = useAppState()
  const [{ connectedChain }] = useSetChain()

  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(
    "alienworlds-ui-teleport-landing.jpg"
  )

  const getImageFilename = (account1: string, account2: string) => {
    return `alienworlds-ui-teleport-connected-${account1.toLowerCase()}-${account2.toLowerCase()}.jpg`
  }

  const accountImageMapping = {
    WAX: {
      Binance: getImageFilename("wax", "binance"),
      Ethereum: getImageFilename("wax", "eth"),
    },
    Anchor: {
      Binance: getImageFilename("anchor", "binance"),
      Ethereum: getImageFilename("anchor", "eth"),
    },
  }

  useEffect(() => {
    const { account } = teleport
    const waxAccount = account && account.wax
    const isBinanceAccount = isBSC(connectedChain?.id)
    const isEthereumAccount = connectedChain && !isBinanceAccount

    // Transaction page
    if (teleport.teleportStep === 2) {
      setSelectedBackgroundImage("alienworlds-ui-teleport-transactions.jpg")
      return
    }

    if (account) {
      const { name, activeAuthenticatorName } = waxAccount || {}

      if (name && [AccountType.WAX, AccountType.ANCHOR].includes(activeAuthenticatorName)) {
        animateGradientsLoggedInWallet()
        if (isBinanceAccount) {
          setSelectedBackgroundImage(
            accountImageMapping[activeAuthenticatorName][AccountType.BINANCE]
          )
        } else if (isEthereumAccount) {
          setSelectedBackgroundImage(accountImageMapping[activeAuthenticatorName][AccountType.ETH])
          animateOtherWallet()
        } else {
          setSelectedBackgroundImage("alienworlds-ui-teleport-logins.jpg")
        }
      } else {
        setSelectedBackgroundImage("alienworlds-ui-teleport-logins.jpg")
        animateGradientsLoggedOutWallet()
      }
    }
  }, [teleport.ual, connectedChain, teleport.teleportStep])

  return (
    <Box position="fixed" overflow="hidden" width="100%" height="100%">
      <MotionBox
        key={selectedBackgroundImage}
        position="absolute"
        overflow="hidden"
        inset={0}
        pointerEvents="none"
        css={css`
          background-image: url(/images/bg/${selectedBackgroundImage});
          background-repeat: no-repeat;
          background-size: cover;
          height: 100vh;
          width: 100vw;
        `}
        initial={{ opacity: 0, translateY: 250, scale: 1.5 }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
          transition: { delay: 0.1, duration: 1.5 },
        }}
        exit={{ opacity: 0, transition: { duration: 1.5 } }}
      />
      <div className="gradients grad1" />
      <div className="gradients grad2" />
      <div className="gradients gradwax1" />
      <div className="gradients gradwax2" />
      <div className="gradients gradother1" />
      <div className="gradients gradother2" />
    </Box>
  )
}

export { Background }
