import { activeAuthenticator } from "util/api-util"

import { WaxJS } from "@waxio/waxjs/dist"
import { forEach, toLower } from "lodash"
import { Transaction, TransactionCategories } from "store/teleport/types"

export const getWaxTLMBalance = async (wax: WaxJS) => {
  // @ts-ignore
  const res = await wax.rpc.get_currency_balance(
    "alien.worlds",
    // @ts-ignore
    wax.userAccount
  )

  return Number(res[0].replace(" TLM", ""))
}

export const getUALTLMBalance = async (ual: Object) => {
  // @ts-ignore
  if (ual && ual.activeAuthenticator) {
    const res = await activeAuthenticator(ual).rpc.get_currency_balance(
      "alien.worlds",
      // @ts-ignore
      ual.activeUser.accountName
    )

    if (res && res[0]) {
      return Number(res[0].replace(" TLM", ""))
    }
    return 0
  }
  return 0
}

export const executeTransact = async (actions: any[], cosign = true) => {
  let Guardeosio: new (provider: WaxJS, actions: any[]) => any
  const client: WaxJS = null

  if (cosign && Guardeosio) {
    const guardEosioInstance = new Guardeosio(client, actions)
    const { guardProvider, guardActions } = await guardEosioInstance.init()

    try {
      const result = await guardProvider.api.transact(
        {
          actions: guardActions,
        },
        {
          blocksBehind: 3,
          expireSeconds: 1200,
        }
      )

      return result
    } catch (error: unknown) {
      return null
    }
  }

  const clonedClient = {
    ...client,
  }
  clonedClient.api.rpc.endpoint = "https://waxnode.alienworlds.io"

  try {
    const result = await clonedClient.api.transact(
      {
        actions,
      },
      {
        blocksBehind: 3,
        expireSeconds: 30,
      }
    )

    return result
  } catch (error: unknown) {
    //  options.onTransactionError(error)
    return null
  }
}

export const cancelTransferWaxEth = (wax: WaxJS) => {
  // @ts-ignore
  const account = wax.userAccount

  return wax.api.transact(
    {
      actions: [
        {
          account: "other.worlds",
          name: "delreceipts",
          authorization: [
            {
              actor: account,
              permission: "active",
            },
          ],
          data: {},
        },
      ],
    },
    {
      blocksBehind: 3,
      expireSeconds: 30,
      broadcast: true,
    }
  )
}

export const transferWaxEth = async (user, quantity, destinationChainId, destinationAddress) => {
  const account = user.accountName

  const actions = [
    {
      account: "alien.worlds",
      name: "transfer",
      authorization: [
        {
          actor: account,
          permission: "active",
        },
      ],
      data: {
        from: account,
        to: "other.worlds",
        quantity: `${parseFloat(quantity).toFixed(4)} TLM`,
        memo: "Teleport",
      },
    },
    {
      account: "other.worlds",
      name: "teleport",
      authorization: [
        {
          actor: account,
          permission: "active",
        },
      ],
      data: {
        from: account,
        quantity: `${parseFloat(quantity).toFixed(4)} TLM`,
        chain_id: destinationChainId,
        eth_address: `${destinationAddress.replace("0x", "")}000000000000000000000000`,
      },
    },
  ]

  return user.signTransaction({ actions }, { blocksBehind: 3, expireSeconds: 30, broadcast: true })
}

export const cancelTransferWaxtEth = async (user, teleportId) => {
  const account = user.accountName
  const actions = [
    {
      account: "other.worlds",
      name: "cancel",
      authorization: [
        {
          actor: account,
          permission: "active",
        },
      ],
      data: {
        id: teleportId,
      },
    },
  ]

  return user.signTransaction({ actions }, { blocksBehind: 3, expireSeconds: 30, broadcast: true })
}

export const loadUALTeleports = async (ual: any, web3Wallet: any): Promise<Array<Transaction>> => {
  const teleports = []
  const account = ual.activeUser.accountName

  const result = await activeAuthenticator(ual).rpc.get_table_rows({
    code: "other.worlds",
    scope: "other.worlds",
    table: "teleports",
    index_position: 2,
    key_type: "i64",
    lower_bound: account,
    upper_bound: account,
    reverse: true,
    limit: 1000,
  })

  await forEach(result.rows, (row: Transaction) => {
    row.category = TransactionCategories.FROMWAX
    row.completed = row.claimed
    row.beingClaimed = false
    row.errorMessage = null
    row.claimable = row.oracles.length >= 5 && !row.completed
    row.correct_login =
      toLower(`0x${row.eth_address.substr(0, 40)}`) === toLower(web3Wallet.accounts[0].address)
    row.correct_chain = false
    if (web3Wallet.chains[0].id === "0x38" && row.chain_id === 2) {
      row.correct_chain = true
    } else if (web3Wallet.chains[0].id === "0x1" && row.chain_id === 1) {
      row.correct_chain = true
    }
    const tmpQty = row.quantity.replace(/ TLM/g, "")
    row.quantity = `${Number(tmpQty.toString())} TLM`
    teleports.push(row)
  })

  const resultWeb3Wallet = await activeAuthenticator(ual).rpc.get_table_rows({
    code: "other.worlds",
    scope: "other.worlds",
    table: "receipts",
    index_position: 3,
    key_type: "i64",
    lower_bound: account,
    upper_bound: account,
    reverse: true,
    limit: 1000,
  })
  await forEach(resultWeb3Wallet.rows, (row) => {
    row.category = TransactionCategories.TOWAX

    let ethApproversCount = 0
    if (row.approvers && row.approvers.length > 0) {
      forEach(row.approvers, (approver) => {
        const ap = approver.split(".")
        if (ap && ap.length > 0) {
          if (ap[0] === "o1" || ap[0] === "o2" || ap[0] === "o3") {
            ethApproversCount += 1
          }
        }
      })
    }

    if (ethApproversCount >= 3) {
      row.isEth = true
    } else {
      row.isEth = false
    }

    const tmpQty = row.quantity.replace(/ TLM/g, "")
    row.quantity = `${Number(tmpQty.toString())} TLM`
    teleports.push(row)
  })

  return teleports
}
