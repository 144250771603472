export enum TransactionCategories {
  FROMWAX,
  TOWAX,
}

export type Transaction = {
  time: number
  account: string
  quantity: string
  beingClaimed: boolean
  chain_id: number
  eth_address: string
  oracles: string[]
  signatures: string[]
  claimed: number
  category: TransactionCategories
  completed: number
  claimable: boolean
  correct_login: boolean
  correct_chain: boolean
  id: number
  transactionDate: Date
  date: Date
  isEth: boolean
  errorMessage: string
}
