import { padStringWithZeros } from "util/helpers"
import { walletAddressTruncate } from "util/string"

import { FC, useState, useEffect } from "react"

import { BenanceIcon, ForwardIcon, TriliumIcon2, WaxIcon2 } from "@alien-worlds/icons"
import {
  Box,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  useBreakpointValue,
  HStack,
} from "@chakra-ui/react"
import { useWallets } from "@web3-onboard/react"
import { Colors } from "colors"
import { toNumber, get, includes } from "lodash"
import { ErrorIcon } from "react-hot-toast"
import { useActions, useAppState } from "store"
import { withUAL } from "ual-reactjs-renderer"

import { AccountType, WaxAccounts, MetamaskAccounts } from "../constants"

type TransferModalProps = {
  transferData: { source: string; target: string }
  onClose: () => void
  ual: any
}

type TransferData = {
  name: string
  balance: number
}

type BalanceStatus = {
  insufficient: boolean
  balance: number
}

const TransferModal: FC<TransferModalProps> = ({ transferData, onClose, ual }) => {
  const { teleport } = useAppState()
  const actions = useActions()
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" })
  const connectedWallets = useWallets()
  const defaultTransferData = { name: "", balance: 0 }
  const defaultBalanceStatus = { insufficient: false, balance: 0 }
  const currentTransformValue = useBreakpointValue({ base: "rotate(90deg)", md: "rotate(0deg)" })
  const [source, setSource] = useState<TransferData | null>(defaultTransferData)
  const [target, setTarget] = useState<TransferData | null>(defaultTransferData)
  const [balanceStatus, setBalanceStatus] = useState<BalanceStatus>(defaultBalanceStatus)
  const [amountToTransfer, setAmountToTransfer] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const getBalanceByName = (name: string) => {
    switch (name) {
      case AccountType.WAX:
      case AccountType.ANCHOR:
        return get(teleport, "account.wax.balance", 0)
      case AccountType.BINANCE:
      case AccountType.ETH:
        return padStringWithZeros(
          get(connectedWallets, "[0].accounts[0].secondaryTokens[0].balance", 0).toString()
        )
      default:
        return 0
    }
  }

  const getLiquidBalanceText = (name: string) => {
    let liquidBalanceText = ""

    switch (name) {
      case AccountType.WAX:
      case AccountType.ANCHOR:
        liquidBalanceText += "WAX"
        break
      case AccountType.BINANCE:
        liquidBalanceText += "BSC"
        break
      case AccountType.ETH:
        liquidBalanceText += "ETH"
        break
      default:
        liquidBalanceText += ""
        break
    }

    return `${liquidBalanceText} Liquid Balance`
  }

  const validateSourceTransfer = ({ name, balance }: TransferData) => {
    const waxMinimumAmount = 100
    const metamaskMinimumAmount = 1

    if (includes(WaxAccounts, name) && balance < waxMinimumAmount) {
      setBalanceStatus({ insufficient: true, balance: waxMinimumAmount })
    } else if (includes(MetamaskAccounts, name) && balance < metamaskMinimumAmount) {
      setBalanceStatus({ insufficient: true, balance: metamaskMinimumAmount })
    } else {
      setBalanceStatus(defaultBalanceStatus)
    }
  }

  const TransferIcon: FC<{ transferType: string }> = ({ transferType }) => {
    switch (transferType) {
      case AccountType.ANCHOR:
      case AccountType.WAX:
        return <WaxIcon2 width={130} height={56} />
      case AccountType.BINANCE:
        return (
          <>
            <Text fontSize={16} fontWeight="bold">
              {walletAddressTruncate(get(connectedWallets, "[0].accounts[0].address"))}
            </Text>
            <BenanceIcon boxSize={56} color={Colors.SNOW_WHITE} />
          </>
        )
      case AccountType.ETH:
        return (
          <>
            <Text fontSize={16} fontWeight="bold">
              {walletAddressTruncate(get(connectedWallets, "[0].accounts[0].address"))}
            </Text>
            <TriliumIcon2 boxSize={56} color={Colors.SNOW_WHITE} />
          </>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (transferData) {
      const sourceData = {
        name: transferData.source,
        balance: toNumber(getBalanceByName(transferData.source)),
      }
      setSource(sourceData)
      setTarget({
        name: transferData.target,
        balance: toNumber(getBalanceByName(transferData.target)),
      })

      validateSourceTransfer(sourceData)
    }
  }, [transferData])

  useEffect(() => {
    if (
      amountToTransfer &&
      ((transferData.source === AccountType.WAX && toNumber(amountToTransfer) < 100) ||
        (transferData.source !== AccountType.WAX && toNumber(amountToTransfer) < 1))
    )
      setError(true)
    else setError(false)
  }, [amountToTransfer])

  return (
    <Modal size="full" isOpen={teleport.transferModal} onClose={onClose}>
      <ModalOverlay backgroundColor={Colors.BLACK_SOLID_70} backdropFilter="blur(5px)" />
      <ModalContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={50}
        background="transparent"
      >
        <ModalBody display="flex" alignItems="center" justifyContent="center" w="100%">
          <Flex direction="column" alignItems="center" gap={6}>
            <Box>
              <ForwardIcon
                color={Colors.CARIBBEAN_GREEN}
                width={50}
                height={33}
                style={{ transform: currentTransformValue }}
                // transform={{ base: "rotate(90deg)", md: "rotate(0deg)" }}
              />
            </Box>

            <Flex
              direction={{ base: "column", md: "row" }}
              fontFamily="Orbitron"
              fontSize="25px"
              letterSpacing="3px"
              alignItems="center"
            >
              <Text>Transfer from</Text>
              <Text color={Colors.RADICAL_RED}>&nbsp;{source.name}&nbsp;</Text>
              <Text>to</Text>
              <Text color={Colors.CARIBBEAN_GREEN}>&nbsp;{target.name}</Text>
            </Flex>

            <Flex
              justifyContent="center"
              alignItems="center"
              gap={{ base: 3, md: 10 }}
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                width={200}
                justifyContent={{ base: "center", md: "flex-end" }}
                alignItems="center"
                gap={4}
              >
                <TransferIcon transferType={source.name} />
              </Flex>
              <Text>to</Text>
              <Flex width={200} gap={4} alignItems="center">
                <TransferIcon transferType={target.name} />
              </Flex>
            </Flex>

            <Text fontSize={18} fontWeight="bold" color={Colors.DI_SERRIA}>
              {getLiquidBalanceText(source.name)}
            </Text>

            <Box>
              <Text
                fontSize={28}
                fontWeight="500"
                color={balanceStatus.insufficient ? Colors.RADICAL_RED : Colors.SNOW_WHITE}
                fontFamily="Orbitron"
              >
                {source.balance} TLM
              </Text>
              {balanceStatus.insufficient && (
                <Text fontSize={15} fontWeight="500" color={Colors.GRAY_CHATEAU}>
                  Minimum {balanceStatus.balance} TLM
                </Text>
              )}
            </Box>

            <Flex
              direction="column"
              gap={5}
              alignItems="center"
              display={balanceStatus.insufficient ? "none" : "flex"}
            >
              {error && (
                <HStack>
                  <ErrorIcon />
                  <Text fontFamily="Orbitron" color={Colors.AMARANTH}>
                    Transfer is below minimum of {transferData.source === AccountType.WAX ? 100 : 1}{" "}
                    TLM.
                  </Text>{" "}
                </HStack>
              )}
              <Text fontWeight="bold" color={Colors.DUSTY_GRAY} fontSize="15px">
                input amount
              </Text>

              <Box>
                <Input
                  disabled={balanceStatus.insufficient}
                  fontFamily="Orbitron"
                  fontSize="27px"
                  color={Colors.SNOW_WHITE}
                  borderRadius="0"
                  width="full"
                  height="55px"
                  textAlign="center"
                  borderStyle="solid"
                  borderWidth="2px"
                  marginBottom="18px"
                  _focus={{ outline: 0 }}
                  placeholder="0"
                  value={amountToTransfer}
                  onChange={(e) => setAmountToTransfer(e.target.value)}
                  type="number"
                />
              </Box>
            </Flex>

            <Flex
              gap={2}
              wrap={{ base: "wrap-reverse", md: "nowrap" }}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                fontFamily="Orbitron"
                bg={Colors.RADICAL_RED}
                borderColor={Colors.RADICAL_RED}
                _hover={{
                  bg: "transparent",
                  borderColor: Colors.RADICAL_RED,
                  color: Colors.RADICAL_RED,
                  transform: "scale(0.95)",
                  transition: "200ms",
                }}
                borderWidth={2}
                borderRadius={0}
                size={buttonSize}
                fontWeight="light"
                width="full"
                letterSpacing={5}
                onClick={() => onClose()}
              >
                CANCEL
              </Button>
              <Button
                fontFamily="Orbitron"
                color={Colors.BLACK_SOLID_100}
                bg={Colors.CARIBBEAN_GREEN}
                borderColor={Colors.CARIBBEAN_GREEN}
                isLoading={teleport.isTransferring}
                _hover={{
                  bg: Colors.SNOW_WHITE,
                  borderColor: Colors.SNOW_WHITE,
                  color: Colors.CARIBBEAN_GREEN,
                  transform: "scale(0.95)",
                  transition: "200ms",
                }}
                borderWidth={2}
                borderRadius={0}
                size={buttonSize}
                fontWeight="light"
                letterSpacing={5}
                width="full"
                disabled={balanceStatus.insufficient || error}
                onClick={async () => {
                  if ([AccountType.WAX, AccountType.ANCHOR].includes(transferData.source)) {
                    await actions.teleport.setTransferring(true)
                    actions.teleport.transferFromWaxToETH({
                      quantity: toNumber(amountToTransfer),
                      ual,
                    })
                  } else {
                    await actions.teleport.setTransferring(true)
                    actions.teleport.transferFromETHToWax({
                      quantity: toNumber(amountToTransfer),
                    })
                  }
                }}
              >
                APPROVE
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default withUAL(TransferModal)
