import { WalletState } from "@web3-onboard/core"
import { Transaction } from "store/teleport/types"

export type WalletInfo = {
  id: number
  name: string
  balance: number
}

export type AlertModal = {
  doShow: boolean
  type: "success" | "error"
  message: string
  onClose: () => void
}

export type TeleportState = {
  web3Wallet: WalletState
  teleportStep: number
  transactions: Transaction[]
  account: {
    wax: {
      name: string
      balance: number | null
      chainId: number
      className: string
      activeAuthenticatorName: string
    }
  }
  errorMessage: string
  errorModal: string

  transferModal: boolean
  claimings: any
  claimed: any
  isLoadingTeleports: boolean
  ual: any
  isLogout: boolean
  errorSelectedItem: {
    id: number
    errorMessage: string
  }
  isTransferring: boolean
  selectedBackgroundImage: string
  alertModal: AlertModal
}

export const defaultState: TeleportState = {
  web3Wallet: null,
  errorSelectedItem: null,
  transactions: [],
  teleportStep: 1,
  transferModal: false,
  account: {
    wax: {
      name: null,
      balance: null,
      chainId: 0,
      className: null,
      activeAuthenticatorName: null,
    },
  },

  errorMessage: null,
  errorModal: null,
  claimings: [],
  claimed: [],
  isLoadingTeleports: false,
  ual: null,
  isLogout: false,
  isTransferring: false,
  selectedBackgroundImage: null,
  alertModal: {
    doShow: false,
    type: null,
    message: null,
    onClose: null,
  },
}

export const state: TeleportState = {
  ...defaultState,
}
