import { isBSC } from "util/helpers"
import { config } from "util/teleportConfig"

import { BenanceIcon, TriliumIcon2, ForwardIcon, WaxIcon2, SwitchIcon } from "@alien-worlds/icons"
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Button as UIButton,
} from "@chakra-ui/react"
import { useSetChain } from "@web3-onboard/react"
import { Colors } from "colors"
import { get } from "lodash"
import moment from "moment"
import { useActions, useAppState } from "store"
import { Transaction, TransactionCategories } from "store/teleport/types"

type TeleportTransactionItemType = {
  item: Transaction
  ual: any
}

const ClaimButton = ({ locked, onClickClaim }) => {
  return (
    <UIButton
      height="46px"
      fontFamily="Orbitron"
      fontSize="15px"
      isDisabled={locked}
      letterSpacing="1px"
      color={Colors.BLACK_SOLID_100}
      fontWeight="500"
      borderRadius="0"
      backgroundColor={Colors.CARIBBEAN_GREEN}
      padding="0 18px 0 18px"
      _focus={{ outline: 0 }}
      _hover={{
        transform: `scale(${locked ? "1" : "0.95"})`,
        transition: "0.3s",
      }}
      cursor={locked ? "not-allowed" : "pointer"}
      filter={`grayscale(${locked ? 1 : 0})`}
      // disable until maintenance is completed

      onClick={onClickClaim}
    >
      Claim
    </UIButton>
  )
}

const CancelClaimButton = ({ locked, onClickCancel }) => {
  return (
    <UIButton
      height="45px"
      fontFamily="Orbitron"
      fontSize="15px"
      letterSpacing="1px"
      color={Colors.BLACK_SOLID_100}
      fontWeight="500"
      borderRadius="0"
      backgroundColor="transparent"
      border={`1px solid ${Colors.AMARANTH}`}
      padding="0 18px 0 18px"
      _focus={{ outline: 0 }}
      _hover={{
        transform: `scale(${locked ? "1" : "0.95"})`,
        transition: "0.3s",
        backgroundColor: Colors.AMARANTH,
        color: "#fff",
      }}
      cursor={locked ? "not-allowed" : "pointer"}
      filter={`grayscale(${locked ? 1 : 0})`}
      marginLeft="10px"
      onClick={onClickCancel}
    >
      Cancel
    </UIButton>
  )
}

const StatusOrAction = ({
  item,
  onClickClaim,
  onClickCancelClaim,
  isClaimableAndCompletedFromWax,
}: {
  item: Transaction
  onClickClaim: (selectedItem: Transaction) => void
  onClickCancelClaim: (selectedItem: Transaction) => void
  isClaimableAndCompletedFromWax: boolean
}) => {
  const [{ connectedChain }, setChain] = useSetChain()
  const { teleport } = useAppState()
  let isClaimExpired = false
  if (item.transactionDate) {
    const diff = new Date().getTime() - new Date(item.transactionDate).getTime()
    isClaimExpired = diff / (1000 * 3600 * 24) > 30
  }

  return (
    <Flex
      minH="60px"
      alignItems="center"
      maxW="200px"
      alignContent="center"
      gap={2}
      justifyContent="center"
    >
      {/* When Transaction is completed and claimed */}
      {item.completed === 1 && (
        <Text
          fontFamily="Titillium Web"
          color="#999999"
          fontSize="20px"
          fontWeight="700"
          letterSpacing="1px"
        >
          {item.category === TransactionCategories.TOWAX
            ? "RECEIVED"
            : item.category === TransactionCategories.FROMWAX
            ? "CLAIMED"
            : ""}
        </Text>
      )}
      {/* When Transaction is completed and not claimed */}
      {item.completed === 0 && isClaimExpired && item.correct_login && item.correct_chain && (
        <CancelClaimButton locked={false} onClickCancel={() => onClickCancelClaim(item)} />
      )}

      {!item.claimed && item.completed === 0 && item.correct_login && item.correct_chain && (
        <ClaimButton
          locked={!isClaimableAndCompletedFromWax || teleport.claimings[item.id]}
          onClickClaim={() => {
            onClickClaim(item)
          }}
        />
      )}

      {/* When Transaction is completed and not correct login */}
      {!item.correct_login && !item.completed && (
        <Text fontFamily="Titillium Web" fontSize="16px">
          Login with the correct account {`0x${(item.eth_address || "").substring(0, 20)}`}
        </Text>
      )}
      {/* When Transaction is completed and not correct chain */}
      {!item.correct_chain && item.correct_login && !item.completed && (
        <Flex direction="row" gap={2} alignItems="center">
          <SwitchIcon
            boxSize="32px"
            aria-label=""
            onClick={() =>
              setChain({
                chainId: isBSC(connectedChain.id)
                  ? `0x${config.ethChainId}`
                  : `0x${config.bscChainId}`,
              })
            }
          />
          <Text fontFamily="Titillium Web" fontSize="16px">
            Switch to the correct chain
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const TeleportTransactionViewItem = ({ item, ual }: TeleportTransactionItemType) => {
  const actions = useActions()
  const isBelowMd = useBreakpointValue({ base: true, md: false })
  const { teleport } = useAppState()
  const isWaxWallet = !!get(ual, "activeAuthenticator.wax", false)

  const transDate = item.transactionDate
    ? moment(item.transactionDate).local().format("MMM DD, YYYY HH:mm")
    : item.date
    ? moment(new Date(item.date)).local().format("MMM DD, YYYY HH:mm")
    : null

  /* Arrow Colors */

  let isClaimableAndCompletedFromWax = true
  if (item.category === TransactionCategories.FROMWAX) {
    if (!item.claimable && !item.completed) {
      isClaimableAndCompletedFromWax = false
    }
  }

  let rotation = "rotate(0deg)"
  if (item.category === TransactionCategories.FROMWAX && isBelowMd) {
    rotation = "rotate(90deg)"
  } else if (item.category === TransactionCategories.TOWAX && isBelowMd) {
    rotation = "rotate(270deg)"
  } else if (item.category === TransactionCategories.TOWAX) {
    rotation = "rotate(180deg)"
  }
  const handleClaim = () => {
    actions.teleport.claim({
      ual,
      selectedItem: item,
    })
  }

  const handleCancelClaim = (selectedItem: Transaction) => {
    actions.teleport.cancelClaim({ selectedItem, ual })
  }
  const claimabableAndNotCompleted =
    (!item.completed && isClaimableAndCompletedFromWax) ||
    item.claimed ||
    (item.completed && TransactionCategories.TOWAX)
  return (
    <Flex
      opacity={claimabableAndNotCompleted ? 1 : 0.5}
      justifyContent="center"
      w={{ base: "100%", md: "90%" }}
      alignItems="center"
    >
      <Grid
        gridTemplateColumns={{ base: "repeat(1,1fr)", md: "repeat(5,1fr)" }}
        alignItems="center"
        gap={4}
        justifyItems="center"
        ml={{ base: 0, lg: 40 }}
      >
        <GridItem>
          <Flex>{isWaxWallet && <WaxIcon2 boxSize={112} />}</Flex>
        </GridItem>
        <GridItem>
          <Flex>
            <ForwardIcon
              transform={rotation}
              boxSize={40}
              color={
                item.category === TransactionCategories.FROMWAX && item.completed
                  ? Colors.GRAY
                  : Colors.CARIBBEAN_GREEN
              }
            />
          </Flex>
        </GridItem>
        <GridItem>
          <Flex direction="column" alignItems="center" gap={0} minW={200}>
            <Text>{transDate}</Text>
            <Text fontWeight={500} fontFamily="Orbitron" fontSize={24}>
              {item.quantity}
            </Text>
            {!claimabableAndNotCompleted && <Text fontWeight="bold">Pending...</Text>}
            {teleport.claimings[item.id] && <Text fontWeight="bold">Claim Pending...</Text>}
            {teleport.claimed[item.id] && (
              <Text fontWeight="bold" color={Colors.AMARANTH}>
                Claimed
              </Text>
            )}
            {teleport.errorSelectedItem && teleport.errorSelectedItem.id === item.id && (
              <Text fontWeight="bold" color={Colors.RADICAL_RED} align="center">
                {teleport.errorSelectedItem.errorMessage}
              </Text>
            )}
          </Flex>
        </GridItem>
        <GridItem>
          <Flex>
            <ForwardIcon
              transform={rotation}
              boxSize={40}
              color={
                item.category === TransactionCategories.FROMWAX && item.completed
                  ? Colors.GRAY
                  : isClaimableAndCompletedFromWax
                  ? Colors.CARIBBEAN_GREEN
                  : Colors.AMARANTH
              }
            />
          </Flex>
        </GridItem>
        <GridItem minW="400px">
          <Flex
            minW="100%"
            alignContent="center"
            justifyContent={{ base: "center", lg: "flex-start" }}
            mb={4}
          >
            {item.chain_id === 1 || item.isEth ? (
              <TriliumIcon2 boxSize={64} />
            ) : (
              <BenanceIcon boxSize={64} />
            )}
            <StatusOrAction
              item={item}
              onClickClaim={handleClaim}
              onClickCancelClaim={handleCancelClaim}
              isClaimableAndCompletedFromWax={isClaimableAndCompletedFromWax}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export { TeleportTransactionViewItem }
