import { config as teleportConfig } from "util/teleportConfig"

import { useState } from "react"

import { ChakraProvider } from "@chakra-ui/react"
import { createOvermind, Overmind } from "overmind"
import { Provider } from "overmind-react"
import { Toaster } from "react-hot-toast"
import { BrowserRouter } from "react-router-dom"
import { Routers } from "router"
import { anchor, chainWax, wax } from "services"
import { UALProvider } from "ual-reactjs-renderer"

import { config } from "./store"
import { theme } from "./styles/theme"

const Root = () => {
  const [overmind] = useState<Overmind<typeof config>>(createOvermind(config, { devtools: false }))
  return (
    <Provider value={overmind}>
      <ChakraProvider theme={theme}>
        <Toaster position="bottom-right" />
        <UALProvider
          appName="Alien Worlds WAX Authenticator"
          authenticators={[wax, anchor]}
          chains={[chainWax]}
          key={teleportConfig.waxChainId}
        >
          <BrowserRouter>
            <Routers />
          </BrowserRouter>
        </UALProvider>
      </ChakraProvider>
    </Provider>
  )
}

export default Root
