import { CustomMetamask } from "util/metamask"
import { config } from "util/teleportConfig"

import { Wax } from "@eosdacio/ual-wax"
import injectedModule from "@web3-onboard/injected-wallets"
import { ProviderLabel, WalletFilters } from "@web3-onboard/injected-wallets/dist/types"
import { init } from "@web3-onboard/react"
import walletConnectModule from "@web3-onboard/walletconnect"
import { isNaN, map } from "lodash"
import { Anchor } from "ual-anchor"

import alienworldsIcon from "./assets/icons/alien-worlds-icon.svg"
import alienworldslogo from "./assets/icons/alien-worlds-logo.svg"

const splitEndpoint = (endpoint: any) => {
  const [protocol, hostPort] = endpoint.split("://")
  const [host, portStr] = hostPort.split(":")
  let port = Number(portStr)

  if (isNaN(port)) {
    port = protocol === "https" ? 443 : 80
  }

  return {
    protocol,
    host,
    port,
  }
}
export const chainWax = {
  chainId: config.waxChainId,
  rpcEndpoints: [splitEndpoint(config.waxEndpoint)],
}
// @ts-ignore
export const wax = new Wax([chainWax], { appName: "Alien Worlds" })
export const anchor = new Anchor([chainWax], { appName: "Alien Worlds" })
// Replace with your DApp's Infura ID

const notSupportedWallets: WalletFilters = {
  [ProviderLabel.ApexWallet]: false,
  [ProviderLabel.BifrostWallet]: false,

  [ProviderLabel.BitKeep]: false,
  [ProviderLabel.Bitski]: false,
  [ProviderLabel.BlockWallet]: false,
  [ProviderLabel.Brave]: false,
  [ProviderLabel.Coinbase]: false,
  [ProviderLabel.Core]: false,
  [ProviderLabel.DeFiWallet]: false,
  [ProviderLabel.Enkrypt]: false,
  [ProviderLabel.Exodus]: false,
  [ProviderLabel.Frame]: false,
  [ProviderLabel.Frontier]: false,
  [ProviderLabel.GameStop]: false,
  [ProviderLabel.InfinityWallet]: false,
  [ProviderLabel.Liquality]: false,
  [ProviderLabel.MathWallet]: false,
  [ProviderLabel.OKXWallet]: false,
  [ProviderLabel.Opera]: false,
  [ProviderLabel.Phantom]: false,
  [ProviderLabel.Rabby]: false,
  [ProviderLabel.Rainbow]: false,
  [ProviderLabel.SafePal]: false,
  [ProviderLabel.Safeheron]: false,
  [ProviderLabel.Sequence]: false,
  [ProviderLabel.Tally]: false,
  [ProviderLabel.Talisman]: false,
  [ProviderLabel.TokenPocket]: false,
  [ProviderLabel.Tokenary]: false,
  [ProviderLabel.Binance]: false,
  [ProviderLabel.XDEFI]: false,
  [ProviderLabel.Zeal]: false,
  [ProviderLabel.Zerion]: false,

  // Force user to use non-injected wallet on mobile and use walletConnect instead
  [ProviderLabel.MetaMask]: ["mobile"],
  [ProviderLabel.Detected]: ["mobile"],
}

const injected = injectedModule({
  // display all wallets even if they are unavailable
  displayUnavailable: false,
  custom: [CustomMetamask],
  // do a manual sort of injected wallets so that MetaMask and Coinbase are ordered first
  filter: notSupportedWallets,
  sort: (wallets) => {
    const values = {}
    map(wallets, (wal) => {
      values[wal.label] = false
    })

    return (
      [...wallets.filter(({ label }) => label !== "metamask")]
        // remove undefined values
        .filter((wallet) => wallet)
    )
  },
})

const walletConnect = walletConnectModule({
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  version: 2,
  dappUrl: process.env.REACT_APP_WALLETCONNECT_DAPP_URL,
})

const wallets = [injected, walletConnect]
const MAINNET_RPC_URL = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
const chainsIDsDevelopment = [
  {
    id: "0x38",
    token: "BNB",
    label: "Binance",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    secondaryTokens: [
      // TLM Token as secondary token to be displayed in the wallet
      {
        address: "0x2222227E22102Fe3322098e4CBfE18cFebD57c95",
      },
    ],
  },
  {
    id: "0x61",
    token: "BNB",
    label: "BNB-Testnet",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  {
    id: "0x1",
    token: "ETH",
    label: "Ethereum Mainnet",
    rpcUrl: MAINNET_RPC_URL,
    secondaryTokens: [
      // TLM Token as secondary token to be displayed in the wallet
      {
        address: "0x888888848b652b3e3a0f34c96e00eec0f3a23f72",
      },
    ],
  },
]

// const chainsIDsProduction = [
//   {
//     id: "0x38",
//     token: "BNB",
//     label: "Binance",
//     rpcUrl: "https://bsc-dataseed.binance.org/",
//   },
// ];

export const initWeb3Onboard = init({
  wallets,
  chains: chainsIDsDevelopment,
  appMetadata: {
    name: "Alien Worlds",
    icon: alienworldsIcon,
    logo: alienworldslogo,
    description: " ",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" },
    ],
  },
})
