import { LogoutOldIcon } from "@alien-worlds/icons"
import { Button } from "@alien-worlds/uikit"
import { Show, Text } from "@chakra-ui/react"
import { Colors } from "colors"
import { withUAL } from "ual-reactjs-renderer"

const Logout = ({ ual }) => {
  if (!ual.activeUser) return null

  return (
    <Button
      variant="dark"
      size={["xs", "xs", "sm", "sm", "sm", "sm"]}
      leftIcon={
        <Show above="md">
          <LogoutOldIcon boxSize={25} color={Colors.RADICAL_RED} />
        </Show>
      }
      onClick={() => ual.logout()}
    >
      <Text color={Colors.RADICAL_RED}>LOG OUT</Text>
    </Button>
  )
}

export default withUAL(Logout)
