import { EIP1193Provider } from "@web3-onboard/core"
import {
  InjectedNameSpace,
  InjectedWalletModule,
  ProviderIdentityFlag,
  ProviderLabel,
} from "@web3-onboard/injected-wallets/dist/types"
import { get } from "lodash"

const filteredWallets = {
  Wombat: "isWombat",
}

const otherProviderFlagsExist = (identity: string, provider: any): boolean => {
  const allowedProviders = { ...ProviderIdentityFlag, ...filteredWallets }
  const otherProviderFlags = Object.values(allowedProviders).filter(
    (id) => id !== identity && id !== ProviderIdentityFlag.Detected
  )

  return otherProviderFlags.some((id) => !!provider[id]) && !get(provider, "isTrust")
}

const getInterfaceFromProvidersArray = (identity: string, checkOtherProviderFlags?: boolean) => {
  // @ts-ignore
  return window.ethereum.providers.find((provider) => {
    return checkOtherProviderFlags
      ? !!provider[identity] && !otherProviderFlagsExist(identity, provider)
      : !!provider[identity]
  })
}

const getInjectedInterface = (
  identity: string,
  checkOtherProviderFlags?: boolean
): (() => Promise<{ provider: EIP1193Provider }>) => {
  // @ts-ignore
  const { ethereum } = window
  return async () => ({
    provider: (ethereum.providers && Array.isArray(ethereum.providers)
      ? getInterfaceFromProvidersArray(identity, checkOtherProviderFlags)
      : ethereum) as EIP1193Provider,
  })
}

export const CustomMetamask: InjectedWalletModule = {
  label: ProviderLabel.MetaMask,
  injectedNamespace: InjectedNameSpace.Ethereum,
  checkProviderIdentity: ({ provider }) =>
    !!provider &&
    !!provider[ProviderIdentityFlag.MetaMask] &&
    !otherProviderFlagsExist(ProviderIdentityFlag.MetaMask, provider),
  getIcon: async () => (await import("../assets/icons/metamask-icon.svg")).default,
  getInterface: getInjectedInterface(ProviderIdentityFlag.MetaMask, true),
  platforms: ["all"],
}
