import { activeAuthenticator } from "util/api-util"

import { Wax } from "@eosdacio/ual-wax"
import { WalletState } from "@web3-onboard/core"
import { Serialize } from "eosjs"
import { ethers } from "ethers"
import { forEach, orderBy, get } from "lodash"
import { AlertModal } from "store/teleport/state"
import { Transaction, TransactionCategories } from "store/teleport/types"
import { Anchor } from "ual-anchor"

import { AccountType } from "../../constants"
import { config } from "../../util/teleportConfig"
import { Context } from "../index"

export const setWeb3Wallet = ({ state }: Context, web3Wallet: WalletState) => {
  state.teleport.web3Wallet = web3Wallet
}

export const setUAL = async ({ state, effects }: Context, ual: Object) => {
  // @ts-ignore
  if (ual && ual.activeUser) {
    // @ts-ignore
    if (ual.activeAuthenticator) {
      // @ts-ignore
      if (ual.activeAuthenticator instanceof Wax) {
        state.teleport.account.wax.activeAuthenticatorName = AccountType.WAX
      }
      // @ts-ignore
      if (ual.activeAuthenticator instanceof Anchor) {
        state.teleport.account.wax.activeAuthenticatorName = AccountType.ANCHOR
      }
    }

    state.teleport.ual = await ual
    // @ts-ignore
    state.teleport.account.wax.name = await ual.activeUser.accountName
    state.teleport.account.wax.className = AccountType.WAX
    // @ts-ignore
    state.teleport.account.wax.balance = await effects.teleport.getUALTLMBalance(ual)
  } else {
    state.teleport.ual = null
    state.teleport.account.wax = {
      name: null,
      balance: 0,
      chainId: 0,
      className: null,
      activeAuthenticatorName: null,
    }
  }

  return true
}

export const setAlertModal = ({ state }: Context, alert: AlertModal) => {
  state.teleport.alertModal = alert
}

export const transferFromWaxToETH = async (
  { state, effects, actions }: Context,
  { quantity, ual }: { quantity: number; ual: any }
) => {
  state.teleport.isTransferring = true
  const chainId =
    config.networks[parseInt(state.teleport.web3Wallet.chains[0].id, 16)].destinationChainId
  const destinationAddress = state.teleport.web3Wallet.accounts[0].address

  try {
    await effects.teleport.transferWaxEth(ual.activeUser, quantity, chainId, destinationAddress)

    actions.teleport.setAlertModal({
      doShow: true,
      type: "success",
      message: "Your Teleport has been sent. Please wait a few moments.",
      onClose: () => {
        actions.teleport.fetchTeleportTransactions()
        actions.teleport.setTransferModal(false)
        actions.teleport.closeAlertModal()
        actions.teleport.setTeleportStep(2)
      },
    })
  } catch (e) {
    let errorMessage = e.message.replace("assertion failure with message: ", "")

    if (!errorMessage || errorMessage.length === 0) {
      errorMessage = "Something went wrong"
    }

    actions.teleport.setAlertModal({
      doShow: true,
      type: "error",
      message: errorMessage,
      onClose: () => {
        actions.teleport.closeAlertModal()
      },
    })
  } finally {
    state.teleport.isTransferring = false
  }
}

// eslint-disable-next-line consistent-return
export const transferFromETHToWax = async (
  { state, actions }: Context,
  { quantity }: { quantity: number }
) => {
  state.teleport.isTransferring = true
  const network = config.networks[parseInt(state.teleport.web3Wallet.chains[0].id, 16).toString()]
  const address = network.tlmContract
  const { abi } = network

  const ethersProvider = new ethers.BrowserProvider(state.teleport.web3Wallet.provider, "any")

  const signer = await ethersProvider.getSigner()
  const contractWithSigner = new ethers.Contract(address, abi, signer)

  try {
    await contractWithSigner.teleport(
      state.teleport.ual.activeUser.accountName,
      quantity * 10000,
      0
    )

    actions.teleport.setAlertModal({
      doShow: true,
      type: "success",
      message: "Your Teleport has been sent. Please wait a few moments.",
      onClose: () => {
        actions.teleport.fetchTeleportTransactions()
        actions.teleport.setTransferModal(false)
        actions.teleport.closeAlertModal()
      },
    })
  } catch (ethersError) {
    const errorMessage = get(ethersError, "info.error.message", "Unknown error")
    actions.teleport.setAlertModal({
      doShow: true,
      type: "error",
      message: errorMessage,
      onClose: () => {
        actions.teleport.closeAlertModal()
      },
    })
  }

  state.teleport.isTransferring = false
}

export const getETHBalance = async ({ state, effects }: Context, { sourceName }) => {
  let bal = null
  if (sourceName === "wax") {
    bal = await effects.teleport.getUALTLMBalance(state.teleport.ual)
  }
  return bal || 0
}

export const setErrorModal = ({ state }: Context, val: string) => {
  state.teleport.errorModal = val
}

export const setTransferModal = ({ state }: Context, val: boolean) => {
  state.teleport.transferModal = val
}
export const setTeleportStep = ({ state }: Context, val: number) => {
  state.teleport.teleportStep = val
}

export const resetErrorSelectedItem = async ({ state }: Context) => {
  state.teleport.errorSelectedItem = null
}

export const fetchTeleportTransactions = async ({ effects, state }: Context) => {
  const { ual, web3Wallet } = state.teleport

  const transactions = await effects.teleport.loadUALTeleports(ual, web3Wallet)
  state.teleport.isLoadingTeleports = true
  const transInOrder = []
  forEach(transactions, (transaction) => {
    if (!transaction.claimed) {
      transaction.transactionDate = new Date(transaction.time * 1000)
      if (!transaction.correct_chain && !transaction.completed) {
        transInOrder.push(transaction)
      } else if (transaction.correct_chain && !transaction.claimable) {
        transInOrder.push(transaction)
      } else if (transaction.correct_chain && transaction.claimable) {
        transInOrder.push(transaction)
      }
    }
  })
  const claimedInOrder = []
  forEach(transactions, (transaction) => {
    if (
      transaction.completed &&
      (transaction.category === TransactionCategories.FROMWAX ||
        transaction.category === TransactionCategories.TOWAX)
    ) {
      transaction.transactionDate =
        transaction.category === TransactionCategories.FROMWAX
          ? new Date(transaction.time * 1000)
          : new Date(transaction.date)
      claimedInOrder.push(transaction)
    }
  })
  const orderedClaimed = orderBy(claimedInOrder, ["transactionDate"], "desc")
  state.teleport.transactions = []
  state.teleport.transactions = orderBy(
    [...transInOrder, ...orderedClaimed],
    ["transactionDate"],
    "desc"
  )

  state.teleport.isLoadingTeleports = false
}

export const setLoadingTeleport = ({ state }: Context, val: boolean) => {
  state.teleport.isLoadingTeleports = val
}

const fromHexString = (hexString) =>
  new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)))
const toHexString = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "")

export const getSignData = async (state: any, teleportId: string, ual: any) => {
  const res = await activeAuthenticator(ual).rpc.get_table_rows({
    code: "other.worlds",
    scope: "other.worlds",
    table: "teleports",
    lower_bound: teleportId,
    upper_bound: teleportId,
    limit: 1,
  })

  if (!res.rows.length) {
    state.teleport.errorModal = `Could not find teleport with ID ${teleportId}`
    throw new Error(`Could not find teleport with ID ${teleportId}`)
  }

  const teleportData = res.rows[0]

  const sb = new Serialize.SerialBuffer({
    textEncoder: new TextEncoder(),
    textDecoder: new TextDecoder(),
  })

  sb.pushNumberAsUint64(teleportData.id)
  sb.pushUint32(teleportData.time)
  sb.pushName(teleportData.account)
  sb.pushAsset(teleportData.quantity)
  sb.push(teleportData.chain_id)
  sb.pushArray(fromHexString(teleportData.eth_address))

  return {
    claimAccount: `0x${teleportData.eth_address}`,
    data: `0x${toHexString(sb.array.slice(0, 69))}`,
    signatures: teleportData.signatures,
  }
}
// eslint-disable-next-line consistent-return
export const claim = async (
  { state, actions }: Context,
  { selectedItem, ual }: { selectedItem: Transaction; ual: any }
) => {
  state.teleport.claimed = []
  state.teleport.claimings[selectedItem.id] = true

  state.teleport.isTransferring = true
  actions.teleport.resetErrorSelectedItem()

  try {
    const signData = await getSignData(state, selectedItem.id.toString(), ual)

    const network = await config.networks[parseInt(state.teleport.web3Wallet.chains[0].id, 16)]

    const ethersProvider = new ethers.BrowserProvider(state.teleport.web3Wallet.provider, "any")

    const signer = await ethersProvider.getSigner()
    const contractWithSigner = new ethers.Contract(network.tlmContract, network.abi, signer)

    try {
      await contractWithSigner.claim(signData.data, signData.signatures)

      state.teleport.claimed[selectedItem.id] = true
      actions.teleport.removeClaiming()
      actions.teleport.fetchTeleportTransactions()
      actions.teleport.setAlertModal({
        doShow: true,
        type: "success",
        message:
          "Your Trilium has been claimed and will show on the network after being confirmed in a block.",
        onClose: () => {
          actions.teleport.closeAlertModal()
        },
      })
    } catch (ethersError) {
      const errorMessage = get(ethersError, "info.error.message", "Unknown error")
      actions.teleport.removeClaiming()
      actions.teleport.setErrorSelectedItem({
        selectedItemId: selectedItem.id,
        errorMessage,
      })
    }
    state.teleport.isTransferring = false
  } catch (e) {
    if (e.error) {
      actions.teleport.removeClaiming()
      actions.teleport.setErrorSelectedItem({
        selectedItemId: selectedItem.id,
        errorMessage: e.error.message,
      })
    }
  }
}

export const removeClaiming = ({ state }: Context) => {
  state.teleport.claimings = []
}

// eslint-disable-next-line consistent-return
export const cancelClaim = async ({ effects }: Context, { selectedItem, ual }) => {
  try {
    effects.teleport.cancelTransferWaxtEth(ual.activeUser, selectedItem.id)
  } catch (e) {
    console.log("CANCEL CLAIM ERROR:", e)
  }
}

export const setErrorSelectedItem = ({ state }: Context, { selectedItemId, errorMessage }) => {
  state.teleport.errorSelectedItem = {
    id: selectedItemId,
    errorMessage,
  }
}

export const setTransferring = async ({ state }: Context, value: boolean) => {
  state.teleport.isTransferring = value
}

export const setSelectedBackgroundImage = ({ state }: Context, value: string) => {
  state.teleport.selectedBackgroundImage = value
}

export const closeAlertModal = ({ state }: Context) => {
  state.teleport.alertModal = {
    doShow: false,
    message: null,
    type: null,
    onClose: null,
  }
}
