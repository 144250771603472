import { useEffect, FC } from "react"

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  VStack,
  Text,
  Img,
} from "@chakra-ui/react"
import TeleportIcon from "assets/icon.png"
import { Colors } from "colors"

export const WarningModal: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isChrome = /chrome/.test((navigator.userAgent || "").toLowerCase())

  useEffect(() => onOpen(), [onOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay
        backgroundColor={isChrome ? Colors.BLACK_SOLID_70 : Colors.BLACK_SOLID_90}
        backdropFilter="blur(5px)"
      />
      <ModalContent backgroundColor="transparent" maxW="100vw" h="100%" pb="10%">
        <ModalBody backgroundColor="transparent" maxW="100vw" h="100%">
          <VStack w="100%" h="100%" alignItems="center" justifyContent="center" spacing="30px">
            <Img alt="Teleport" src={TeleportIcon} height="80px" width="80px" />
            <Text fontFamily="Orbitron" color="white" textAlign="center">
              To guarantee smooth operation of the Teleport, please disable any browser wallet
              extensions except MetaMask
            </Text>
            <Button
              bg="black"
              color={Colors.SNOW_WHITE}
              border={`2px solid ${Colors.SNOW_WHITE}`}
              onClick={onClose}
            >
              <Text
                fontFamily="Orbitron"
                color="white"
                textAlign="center"
                _hover={{ color: Colors.BLACK_SOLID_100 }}
              >
                Confirm
              </Text>
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
