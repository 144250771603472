export const Colors = {
  TUSCANY: "rgba(201, 92, 49, 1)",
  SNOW_WHITE: "rgba(255, 255, 255, 1)",
  CARIBBEAN_GREEN: "rgba(14, 212, 168, 1)",
  DI_SERRIA: "rgba(217, 165, 85, 1)",
  RADICAL_RED: "rgba(255, 59, 82, 1)",
  GRAY_CHATEAU: "rgba(166, 168, 170, 1)",
  BLACK_SOLID_100: "rgba(0, 0, 0, 1)",
  BLACK_SOLID_90: "rgba(0, 0, 0, 0.9)",
  BLACK_SOLID_70: "rgba(0, 0, 0, 0.7)",
  DUSTY_GRAY: "rgba(152, 152, 152, 1)",
  AMARANTH: "rgba(231, 59, 82,1)",
  GRAY: "rgba(128,128,128,1)",
}
