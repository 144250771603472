import { useEffect, useState } from "react"

import { Flex, Spacer, Box } from "@chakra-ui/react"
import { Colors } from "colors"
import { Logo } from "components/header/Logo"
import Logout from "components/header/Logout"
import { WalletAccounts } from "components/header/WalletAccounts"

const Header = () => {
  const [headerColor, setHeaderColor] = useState("transparent")

  const listenScrollEvent = () => {
    if (window.scrollY > 30) {
      setHeaderColor(Colors.BLACK_SOLID_100)
    } else {
      setHeaderColor("transparent")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  })

  return (
    <Flex
      as="header"
      px={{ base: 1, md: 10 }}
      py={5}
      position="sticky"
      top="0"
      bg={headerColor}
      sx={{
        transition: "background-color 0.5s ease-in-out",
        MozTransition: "background-color 0.5s ease-in-out",
        WebkitTransition: "background-color 0.5s ease-in-out",
      }}
      zIndex="sticky"
    >
      <Flex gap={5} alignItems="center">
        <Logout />
        <WalletAccounts />
      </Flex>

      <Spacer display={{ base: "none", lg: "block" }} />

      <Box display={{ base: "none", lg: "block" }}>
        <Logo />
      </Box>
    </Flex>
  )
}

export { Header }
